<template>
    <div class="tgcss-w-full tgcss-pb-2 tgcss-px-1 font-IRANSans">

        <div class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-mb-3">
            <span class="tgcss-text-sm tgcss-font-bold grow">{{title}}</span> 
            <router-link :to="{ name: 'ApplicationNews' , params: { category_id: cat, }}" class="tgcss-opacity-70 tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800">مشاهده‌همه</router-link>                
        </div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <ul v-if="!loading && slider.items" class="tgcss-flex tgcss-flex-col tgcss-overflow-y-auto">
            <!-- ردیف اول -->
            <div class="tgcss-w-full">
                <router-link  v-for="(item, index) in slider.items.data" :key="index" :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}" class="!tgcss-no-underline !tgcss-text-gray-800">
                    <div class="tgcss-flex tgcss-items-start tgcss-border-b tgcss-border-gray-300 tgcss-border-dashed tgcss-w-full tgcss-py-3 tgcss-overflow-hidden"
                    :class="{ ' !tgcss-pt-0 ': index == 0,' !tgcss-border-0 ': index == 4}"
                    >
                        <div class="tgcss-border tgcss-flex tgcss-items-center tgcss-justify-center tgcss-rounded-lg tgcss-overflow-hidden tgcss-h-20 tgcss-min-h-20 tgcss-w-20 tgcss-min-w-20 tgcss-mt-0.5 tgcss-ml-3">
                            <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.image" :alt="item.title">
                        </div>
                        <div class="tgcss-grow">
                            <div class="tgcss-w-full tgcss-h-[62px]">
                                <div class="tgcss-font-semibold tgcss-text-gray-400 tgcss-flex tgcss-items-center tgcss-text-xs2 tgcss-line-clamp-1 tgcss-text-ellipsis tgcss-overflow-hidden">
                                    {{ item.title_second }}
                                </div>
                                <div class="tgcss-font-semibold tgcss-h-12 tgcss-flex tgcss-items-start tgcss-text-sm tgcss-leading-6 tgcss-mb-0.5 tgcss-line-clamp-2 tgcss-text-ellipsis tgcss-overflow-hidden">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div class="tgcss-text-xs2 tgcss-pr-1 tgcss-font-medium tgcss-text-gray-400 tgcss-flex tgcss-items-center">
                                <router-link :to="{ name: 'ApplicationNews' , params: { category_id: item.category_id, }}"  class="tgcss-py-0.5 tgcss-ml-2 !tgcss-no-underline  -tgcss-mr-1 tgcss-font-semibold tgcss-text-xs2 tgcss-px-2 !tgcss-bg-[#ffefd2] !tgcss-text-[#a68953] tgcss-rounded-full">{{ item.category_title }}</router-link>
                                {{ parseDate(item.jalali_created_at).dayOfWeek }} {{ parseDate(item.jalali_created_at).day }} {{ parseDate(item.jalali_created_at).monthName }} {{ parseDate(item.jalali_created_at).year }}
                                
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        
        </ul>
        
        


    </div>
</template>
    
<script>
    export default {
        name: 'newsPosts',
        props: ['cat', 'count', 'title', 'type'],
        components: {},
        data() {
            return {
                slider:[],
                slider_cat:0,
                loading:false
            }
        },
        mounted() {
            this.getSlider();
        },
        computed: {},
        methods: {
            parseDate(dateString) {
                // تاریخ ورودی: یکشنبه 5 اسفند 1403 ساعت 11:31
                const months = [
                    'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
                    'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
                ];
                
                // روزهای هفته
                const daysOfWeek = [
                    'شنبه', 'یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه'
                ];
                
                // جدا کردن روز، ماه، سال
                const dateParts = dateString.match(/(\D+)\s(\d+)\s(\D+)\s(\d+)/);

                const dayOfWeek = dateParts[1];  // روز هفته (فارسی)
                const day = dateParts[2];        // روز
                const month = months.indexOf(dateParts[3]) + 1;  // ماه به شماره
                const monthName = dateParts[3];  // ماه به اسم فارسی
                const year = dateParts[4];       // سال
                
                return { dayOfWeek, day, month, monthName, year };
            },
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
            getSlider(){
                this.loading = true;
                $.ajax({
                    url: ( this.type == 'slug' ? 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+this.cat : 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+this.cat ),
                    method: 'get',
                    complete: (res) => {
                        this.slider = res.responseJSON.response;  
                        this.slider_cat = Object.keys(this.slider.categories)[0];
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  